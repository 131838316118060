<template>
  <div id="nav">
    <div class="bar-content">
      <router-link class="name" to="/" @click.native="closeMenu">Emma Meersman</router-link>
      <div class="tabs">
        <a href="./resume.pdf" target="_blank">Resume</a>
        <router-link to="/software">Software</router-link>
        <router-link to="/hardware">Hardware</router-link>
        <router-link to="/puzzles">Puzzles</router-link>
      </div>
      <button class="menu" @click="toggleMenu">
        <font-awesome-icon :icon="['fas', 'bars']" size="2x"/>
      </button>
    </div>
    <div v-if="isMenuOpen" class="menu-content">
      <a href="./resume.pdf" target="_blank" @click="closeMenu">Resume</a>
      <router-link to="/software" @click.native="closeMenu">Software</router-link>
      <router-link to="/hardware" @click.native="closeMenu">Hardware</router-link>
      <router-link to="/puzzles" @click.native="closeMenu">Puzzles</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NavBar',
  data () {
    return {
      isMenuOpen: false
    }
  },
  created () {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768 && !!this.isMenuOpen) {
        this.closeMenu()
      }
    })
  },
  methods: {
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu () {
      this.isMenuOpen = false
    }
  }
}
</script>
<style>
#nav {
  padding: 22px 30px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.menu-content {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.menu-content a {
  padding: 8px 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #057c09;
  border-bottom: 4px solid #057c09;
}

.name {
  font-size: 30px;
}

.tabs a {
  font-size: 18px;
  margin: 12px;
}

button {
  border: none;
  background-color: transparent;
}

button:focus {
  outline: none;
}

.menu svg {
  color: #2c3e50;
}

@media only screen and (max-width: 767px) {
  .tabs {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }

  .menu-content {
    display: none;
  }
}
</style>
