<template>
  <div id="app">
    <nav-bar></nav-bar>
    <router-view id="page" class="page"></router-view>
    <Footer class="footer" />
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  components: {
    Footer,
    NavBar
  }
}
</script>
<style>
body {
  background-color: rgba(203, 168, 121, 0.35);
  margin: 0;
  background: url("../public/trees.jpg");
  background-size: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

h2, h3, p {
    text-align: left;
}

.page {
  padding: 10px 42px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-top: auto;
  padding: 8px 12px;
}
</style>
