import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: () => import('../views/Hardware.vue')
  },
  {
    path: '/software',
    name: 'software',
    component: () => import('../views/Software.vue')
  },  
  {
    path: '/puzzles',
    name: 'puzzles',
    component: () => import('../views/Puzzles.vue')
  },
  {
    path: '/text-adventure',
    name: 'text adventure',
    component: () => import('../views/TextAdventure.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
