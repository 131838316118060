<template>
  <div id="footer">
    <a href="https://www.linkedin.com/in/emma-meersman-96380855/">
      <font-awesome-layers class="fa-2x">
        <font-awesome-icon :icon="['fas', 'square']" style="color:white" size="1x"/>
        <font-awesome-icon :icon="['fab', 'linkedin']" size="1x"/>
      </font-awesome-layers>
    </a>
    <a href="https://github.com/emeersman">
      <font-awesome-layers class="fa-2x">
        <font-awesome-icon :icon="['fas', 'square']" style="color:white" size="1x"/>
        <font-awesome-icon :icon="['fab', 'github-square']" size="1x"/>
      </font-awesome-layers>
    </a>
    <a href="mailto:emma@emmameersman.com">
      <font-awesome-layers class="fa-2x">
        <font-awesome-icon :icon="['fas', 'square']" style="color:white" size="1x"/>
        <font-awesome-icon :icon="['fas', 'envelope-square']" size="1x"/>
      </font-awesome-layers>
    </a>
    <a href="https://www.instagram.com/meerskat_">
      <font-awesome-layers full-width class="fa-2x">
        <font-awesome-icon :icon="['fas', 'square']" style="color:white" size="1x"/>
        <font-awesome-icon :icon="['fab', 'instagram-square']" size="1x"/>
      </font-awesome-layers>
    </a>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style>
.fa-layers {
	padding: 4px;
}

svg {
  color: #057c09;
}
</style>
