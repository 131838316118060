<template>
  <div class="home">
    <h3>Hi, I'm Emma!</h3>
    <p><!-- I write code (link), do puzzles (link), and read books (link). Or, for a less nerdy opener, I walk places, take pictures, and read books/write puzzles. -->
      I want to use my unique background in computing and design to create enjoyable, impactful experiences for people around the world.
    </p>
    <p>
      I'm based out of Seattle, working at <a href="https://www.vayda.com/">Vayda</a> as a software engineer. Before that, I was at <a href="https://glowforge.com/">Glowforge</a> for three years and <a href="https://lmgtfy.app/?q=google">Google</a> for four. I got a bachelor's degree in Computer Science from <a href="https://www.hmc.edu">Harvey Mudd College</a> and a master's degree in <a href="https://www.hcde.washington.edu/ms">Human-Centered Design and Engineering</a> at the <a href="https://www.washington.edu/">University of Washington</a>.
    </p>
    <p>
      Creating things makes me happy, especially if I get to think outside the box and play around with new hardware and/or software. My interests include mountaineering, digital fabrication, puzzles, aerial silks, and all things Pacific Northwest.
      <!-- Also hiking, travel, yoga, photography (instagram), cooking, wine, SFF (goodreads), board games, nerd media, etc. -->
    </p>
    <p>
      Check out my <a href="https://github.com/emeersman">Github</a> profile and feel free to reach out to me on <a href="https://www.linkedin.com/in/emma-meersman-96380855/">LinkedIn</a> or via email at <a href="mailto:emma@emmameersman.com">emma@emmameersman.com</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
<style scoped>
.home {
  background-color: rgba(4, 99, 7, 0.8);
  backdrop-filter: blur(5px);
  width: 66%;
  border-radius: 0px 12px 12px 0px;
  box-shadow: 5px 5px 5px #424242;
  margin-left: -10px;
  margin-top: 10px;
}

a {
    color: #CD9B1D;
    font-weight: bold;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .home {
    border-radius: 0;
    width: auto;
    box-shadow: none;
  }
}
</style>
